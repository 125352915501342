import { useState, useCallback } from 'react';
import { CircularProgress } from '@mui/material';
import { MapContainer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';

import 'leaflet/dist/leaflet.css';

import { useGetInspectorsQuery } from 'src/store/api';
import { RawResult, SearchResult } from 'src/shared/types';

import { AddAssignmentMap } from './components/AddAssignmentMap';
import { ToolbarMap } from './components/ToolbarMap';
import { TileLayerMap } from './components/TileLayerMap';
import { MarkerMap } from './components/MarkerMap';
import { SearchFieldMap } from './components/SearchFieldMap';
import { SelectedAddressMarker } from './components/SelectedAddressMarker';
import './style.css';

const LeafletMap = () => {
  const [address, setAddress] = useState('');
  const [selectedAddress, setSelectedAddress] = useState<SearchResult<RawResult> | null>(null);
  const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false);

  const { data: inspectors = [], isFetching, isLoading } = useGetInspectorsQuery('');

  const toggleSearchVisibility = useCallback(() => {
    setIsSearchVisible((prev) => !prev);
  }, []);

  const isLoadingOverall = isFetching || isLoading;

  return (
    <div className="w-full h-full flex flex-col gap-4">
      <ToolbarMap />

      {!isLoadingOverall ? (
        <div className="relative w-full h-full flex flex-col gap-4">
          <MapContainer
            doubleClickZoom
            center={[39, -100]}
            zoom={4}
            scrollWheelZoom
            zoomDelta={1}
            style={{
              height: '100%',
              width: '100%',
              position: 'relative',
            }}
          >
            <TileLayerMap />

            {isSearchVisible && (
              <SearchFieldMap
                address={address}
                setAddress={setAddress}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
              />
            )}

            <MarkerClusterGroup>
              {inspectors.map((inspector) => (
                <MarkerMap
                  key={inspector.id}
                  inspector={inspector}
                />
              ))}

              {selectedAddress && <SelectedAddressMarker selectedAddress={selectedAddress} />}
            </MarkerClusterGroup>
          </MapContainer>

          <AddAssignmentMap
            isSearchVisible={isSearchVisible}
            toggleSearchVisibility={toggleSearchVisibility}
          />
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export { LeafletMap };
