import 'survey-core/defaultV2.css';
import 'survey-creator-core/survey-creator-core.css';
import { MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Model } from 'survey-core';

import { ReactComponent as DownloadIcon } from 'src/assets/icons/filled/files/download.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/filled/edit/plus.svg';
import { ReactComponent as MoreVerticalIcon } from 'src/assets/icons/filled/menus/more-vertical.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/outlined/edit/edit.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/outlined/edit/trash.svg';
import { ReactComponent as FillIcon } from 'src/assets/icons/outlined/misc/tabler-layout-sidebar-left-expand.svg';
import { ReactComponent as ClipboardIcon } from 'src/assets/icons/outlined/edit/clipboard.svg';
import { ReactComponent as CopyIcon } from 'src/assets/icons/outlined/edit/copy.svg';
import { Button } from 'src/shared/ui/button';
import { IconButton } from 'src/shared/ui/iconButton';
import { Typography } from 'src/shared/ui/typography';
import { clsx, not, showToastErrorMessage } from 'src/shared/utils';
import { DropDown, DropDownItem } from 'src/shared/ui/dropDown';
import { Icon } from 'src/shared/ui/icon';
import { CardMenuItem } from 'src/shared/ui/schedulerBoard/ui/cardMenuItem';
import { useCopySurveyMutation, useGetSurveysQuery } from 'src/store/api/survey';
import { Role, SurveyEntity } from 'src/shared/types';
import { selectCurrentUser } from 'src/store/slices';
import { Skeleton } from 'src/shared/ui/skeleton';
import { ADMIN_ROLES } from 'src/shared/constants';
import { Spinner } from 'src/shared/ui/spinner';

import { createSurveyPDFModel } from '../SurveyForm/utils';

import { DeleteFormModal } from './Features/DeleteFormModal';

const Forms = () => {
  const navigate = useNavigate();

  const { data: forms = [], isFetching } = useGetSurveysQuery('');
  const [copySurvey, { isLoading: isCopyingForm }] = useCopySurveyMutation();
  const [isDeleteFormModalOpen, setIsDeleteFormModalOpen] = useState(false);
  const [selectedForm, setSelectedForm] = useState<SurveyEntity>();

  const user = useSelector(selectCurrentUser);

  const isAdmin = ADMIN_ROLES.includes(user?.ProviderRoleMatrix?.userRole as Role);
  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;

  const deleteForm = (e: MouseEvent<HTMLButtonElement>, form: SurveyEntity) => {
    e.stopPropagation();
    setSelectedForm(form);
    setIsDeleteFormModalOpen(true);
  };

  const openFormCreator = () => {
    navigate('/forms/create');
  };

  const openAnswersPage = (e: MouseEvent<HTMLButtonElement>, form: SurveyEntity) => {
    e.stopPropagation();

    navigate(`/forms/${form.id}/answers`);
  };

  const openFillForm = (e: MouseEvent<HTMLButtonElement>, form: SurveyEntity) => {
    e.stopPropagation();

    if (isReadonly) {
      return;
    }

    navigate(`/forms/${form.id}`);
  };

  const openEditForm = (e: MouseEvent<HTMLButtonElement>, form: SurveyEntity) => {
    e.stopPropagation();

    navigate(`/forms/${form.id}/edit`);
  };

  const copyForm = async (e: MouseEvent<HTMLButtonElement>, form: SurveyEntity) => {
    e.stopPropagation();

    try {
      setSelectedForm(form);
      await copySurvey({ surveyId: form.id }).unwrap();
    } catch (error) {
      showToastErrorMessage(`There was an error trying to copy ${form.name}`);
    }
  };

  const saveToPDF = (e: MouseEvent<HTMLButtonElement>, form: SurveyEntity) => {
    e.stopPropagation();

    const survey = new Model(form.content);
    createSurveyPDFModel(form.content, survey).save(form.name);
  };

  return (
    <>
      <div className="flex flex-col items-start gap-y-7 pt-9 w-[948px]">
        <div className="flex items-center justify-between w-full">
          <Typography variant="h1">Forms</Typography>

          {isAdmin && (
            <Button
              color="primary"
              size="lg"
              endIcon={<PlusIcon />}
              className="w-[165px]"
              onClick={openFormCreator}
              disabled={isFetching}
            >
              Create Form
            </Button>
          )}
        </div>

        <div className="flex flex-col gap-y-3 w-full max-h-[700px] overflow-y-scroll">
          {isFetching
            ? Array.from({ length: 5 }).map((_, i) => (
                <Skeleton
                  className="h-[74px]"
                  // eslint-disable-next-line
                  key={i}
                />
              ))
            : forms.map((form) => (
                <button
                  key={form.id}
                  type="button"
                  onClick={(e) => openFillForm(e, form)}
                  className={clsx(
                    'flex items-center justify-between gap-x-4 w-full p-4 rounded-lg bg-white border  transition-all duration-200',
                    isReadonly
                      ? 'cursor-default'
                      : 'cursor-pointer hover:border-brandingColor-primary-gradient',
                  )}
                >
                  <Typography
                    variant="p1"
                    fontWeight="bold"
                  >
                    {form.name}
                  </Typography>

                  <DropDown
                    options={{ placement: 'bottom-start' }}
                    config={{
                      itemsElementClassName: clsx(
                        'shadow-[0px_2px_66px_-10px_#0000000F]',
                        'rounded-lg',
                        'bg-bgColor-card',
                      ),
                    }}
                    renderElement={() => (
                      <IconButton
                        color="basic"
                        size="md"
                        iconSize="md"
                        iconClassName="fill-textColor-tertiary"
                        className="bg-bgColor-ticketCard"
                      >
                        <MoreVerticalIcon />
                      </IconButton>
                    )}
                    items={
                      [
                        {
                          value: 'answers',
                          label: (
                            <CardMenuItem
                              onClick={(e) => openAnswersPage(e, form)}
                              startIcon={
                                <Icon
                                  className="fill-textColor-tertiary"
                                  icon={<ClipboardIcon />}
                                />
                              }
                              titleClassName="text-textColor-tertiary"
                            >
                              Survey Answers
                            </CardMenuItem>
                          ),
                        },
                        not(isReadonly)
                          ? {
                              value: 'fill',
                              label: (
                                <CardMenuItem
                                  onClick={(e) => openFillForm(e, form)}
                                  startIcon={
                                    <Icon
                                      className="fill-white"
                                      icon={<FillIcon />}
                                    />
                                  }
                                  titleClassName="text-textColor-tertiary"
                                >
                                  Fill
                                </CardMenuItem>
                              ),
                            }
                          : null,
                        isAdmin
                          ? {
                              value: 'edit',
                              label: (
                                <CardMenuItem
                                  onClick={(e) => openEditForm(e, form)}
                                  startIcon={
                                    <Icon
                                      className="fill-textColor-tertiary"
                                      icon={<EditIcon />}
                                    />
                                  }
                                  titleClassName="text-textColor-tertiary"
                                >
                                  Edit
                                </CardMenuItem>
                              ),
                            }
                          : null,
                        {
                          value: 'pdf',
                          label: (
                            <CardMenuItem
                              onClick={(e) => saveToPDF(e, form)}
                              startIcon={
                                <Icon
                                  className="fill-textColor-tertiary"
                                  icon={<DownloadIcon />}
                                />
                              }
                              titleClassName="text-textColor-tertiary"
                            >
                              Export to PDF
                            </CardMenuItem>
                          ),
                        },
                        isAdmin
                          ? {
                              value: 'copy',
                              label: (
                                <CardMenuItem
                                  onClick={(e) => copyForm(e, form)}
                                  startIcon={
                                    <Icon
                                      className="fill-textColor-tertiary"
                                      icon={<CopyIcon />}
                                    />
                                  }
                                  titleClassName="text-textColor-tertiary"
                                >
                                  Copy
                                </CardMenuItem>
                              ),
                            }
                          : null,
                        isAdmin
                          ? {
                              value: 'delete',
                              label: (
                                <CardMenuItem
                                  onClick={(e) => deleteForm(e, form)}
                                  startIcon={
                                    <Icon
                                      className="fill-semanticColor-danger"
                                      icon={<TrashIcon />}
                                    />
                                  }
                                  titleClassName="text-semanticColor-danger"
                                >
                                  Delete
                                </CardMenuItem>
                              ),
                            }
                          : null,
                      ].filter(Boolean) as DropDownItem[]
                    }
                  />
                </button>
              ))}
        </div>

        <DeleteFormModal
          form={selectedForm}
          isOpen={isDeleteFormModalOpen}
          setIsOpen={setIsDeleteFormModalOpen}
        />
      </div>

      {isCopyingForm && (
        <Spinner
          withBackdrop
          fallbackText={`Copying ${selectedForm?.name} form...`}
        />
      )}
    </>
  );
};

export { Forms };
