import { TileLayer } from 'react-leaflet';

const ATTRIBUTION_TILE_LAYER =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
const URL_TILE_LAYER = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

const TileLayerMap = () => {
  return (
    <TileLayer
      attribution={ATTRIBUTION_TILE_LAYER}
      url={URL_TILE_LAYER}
    />
  );
};

export { TileLayerMap };
