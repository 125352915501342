import dayjs from 'dayjs';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { Filters } from 'src/shared/ui/filters';
import { RootState } from 'src/store';
import { useGetActivitiesByProviderTeamsQuery } from 'src/store/api/activities';
import { IconButton } from 'src/shared/ui/iconButton';
import { Icons } from 'src/assets/icons';
import { ChoosingPeriodButtons } from 'src/shared/ui/choosingPeriodButtons';
import { DateRangeSelector } from 'src/shared/ui/dateRangeSelector';
import { UploadJobsButton } from 'src/shared/ui/uploadJobsButton';
import { Tag } from 'src/shared/ui/tag';
import { SearchableTAFilters, TimePeriod } from 'src/shared/types';
import { FilterSelect } from 'src/shared/ui/filterselect';
import { useGetActivityProcessesFiltersQuery } from 'src/store/api';

import { ActivityGridTable, ActivityJobProcess, AddActivityJobButton } from './ui';
import {
  DATES_VERTICAL_GAP,
  DAY_PADDING_BOTTOM,
  DAY_TITLE_HEIGHT,
  DEFAULT_LOCATION_TIME_ZONE,
  DEFAULT_OWNER_LOCATION_ID,
  HEADER_HEIGHT,
  HOURS_IN_DAY,
  ONE_HOUR_DAY_WIDTH,
  ONE_HOUR_WIDTH,
  POLLING_INTERVAL_ACTIVITIES,
} from './helpers';
import { TodayButton } from './ui/todayButton';

const Activities: FC = () => {
  const params = useParams();

  const [timeZoneState, setTimeZoneState] = useState(dayjs.tz.guess());

  const [searchParams, setSearchParams] = useSearchParams();

  const jobIdSearch = useMemo(
    () => searchParams.get(SearchableTAFilters.JobID) || undefined,
    [searchParams],
  );

  const [filtersSearchValues, setFiltersSearchValues] = useState({
    [SearchableTAFilters.JobID]: jobIdSearch || '',
  });

  const onApplyClick = useCallback(() => {
    if (filtersSearchValues[SearchableTAFilters.JobID]) {
      searchParams.set(SearchableTAFilters.JobID, filtersSearchValues[SearchableTAFilters.JobID]);
      setSearchParams(searchParams);
    }
  }, [filtersSearchValues]);

  const handleChangeSearchValue = useCallback((value: string) => {
    setFiltersSearchValues({
      [SearchableTAFilters.JobID]: value,
    });
  }, []);

  const handleClearAllFilters = useCallback(() => {
    if (jobIdSearch) {
      searchParams.delete(SearchableTAFilters.JobID);
      setSearchParams(searchParams);
    }

    if (filtersSearchValues[SearchableTAFilters.JobID]) {
      setFiltersSearchValues({
        [SearchableTAFilters.JobID]: '',
      });
    }
  }, [jobIdSearch]);

  const hasClearBySearchValues = !!jobIdSearch || !!filtersSearchValues[SearchableTAFilters.JobID];

  const { activityId } = params;

  const datesState = useSelector((state: RootState) => state.calendar.dates);
  const filterPeriod = useSelector((state: RootState) => state.calendar.currentLayout);
  const boardFilters = useSelector((state: RootState) => state.config.boardFilters);

  const isDayPeriod = useMemo(() => filterPeriod === TimePeriod.Day, [filterPeriod]);

  const dates = useMemo(
    () => datesState.map((date) => dayjs(date).format().toString()),
    [datesState],
  );

  const { startDate, endDate } = useMemo(() => {
    const timezoneDates = datesState.map((date) => dayjs.utc(date).tz(timeZoneState));

    const localStartDate = dayjs(timezoneDates[0])
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .toDate();
    const localEndDate = dayjs(timezoneDates[dates.length - 1])
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59)
      .toDate();

    const startDate = dayjs.utc(localStartDate).tz(timeZoneState).toDate().toISOString();
    const endDate = dayjs.utc(localEndDate).tz(timeZoneState).toDate().toISOString();

    return {
      startDate,
      endDate,
    };
  }, [dates, timeZoneState]);

  const { data: activitiesByProviderTeams } = useGetActivitiesByProviderTeamsQuery(
    {
      ...boardFilters,
      startDate,
      endDate,
      jobIdSearch,
    },
    {
      skip: !!activityId,
      refetchOnMountOrArgChange: true,
      pollingInterval: POLLING_INTERVAL_ACTIVITIES,
    },
  );

  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);

  const toggleFilterMenu = () => setIsFilterMenuOpen((prev) => !prev);

  const { data: filters = [] } = useGetActivityProcessesFiltersQuery(
    { startDate, endDate },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: POLLING_INTERVAL_ACTIVITIES,
    },
  );

  const renderFilters = filters.map((filter) => {
    const hasInputSearch = filter.label === SearchableTAFilters.JobID;

    const getOptionsToShow = () => {
      if (!hasInputSearch) {
        return filter.options;
      }

      return filter.options.filter((option) =>
        option.includes(filtersSearchValues[SearchableTAFilters.JobID]),
      );
    };

    const preparedOptions = getOptionsToShow();

    return (
      <FilterSelect
        key={`ActivityFilter-${filter.label}`}
        options={preparedOptions.map((option) => ({
          label: option,
          value: option,
        }))}
        label={filter.label}
        placeholder={`Filter by ${filter.label}`}
        id={filter.label}
        type="board"
        hasInputSearch={hasInputSearch}
        onChangeSearchValue={handleChangeSearchValue}
      />
    );
  });

  const appliedFiltersAmount =
    Object.values(boardFilters).filter((filter) => filter.length > 0).length +
    (jobIdSearch ? 1 : 0);

  const { timeZone, ownerLocationID } = useMemo(() => {
    const firstActivity = activitiesByProviderTeams
      ? activitiesByProviderTeams[0]?.activity[0]
      : null;

    const timeZone =
      activitiesByProviderTeams && firstActivity
        ? firstActivity.OwnerLocation?.LocationTimezone
        : DEFAULT_LOCATION_TIME_ZONE;

    const ownerLocationID =
      activitiesByProviderTeams && firstActivity
        ? firstActivity.ownerLocationID
        : DEFAULT_OWNER_LOCATION_ID;

    return {
      timeZone,
      ownerLocationID,
    };
  }, [activitiesByProviderTeams]);

  useEffect(() => {
    if (timeZone !== timeZoneState) {
      setTimeZoneState(timeZone);
    }
  }, [timeZone, timeZoneState]);

  const containerMinWidth = useMemo(() => {
    const daysCount = isDayPeriod ? 1 : dates.length;

    return daysCount * HOURS_IN_DAY * (isDayPeriod ? ONE_HOUR_DAY_WIDTH : ONE_HOUR_WIDTH);
  }, [isDayPeriod]);

  const [containerWidth, setContainerWidth] = useState(containerMinWidth);

  const handleSetContainerWidth = (width?: number) => {
    if (width) {
      setContainerWidth(width);
    } else {
      setContainerWidth(containerMinWidth);
    }
  };

  useEffect(() => {
    setContainerWidth(containerMinWidth);
  }, [containerMinWidth]);

  const topSpace = HEADER_HEIGHT + DATES_VERTICAL_GAP + DAY_TITLE_HEIGHT + DAY_PADDING_BOTTOM;

  return (
    <div>
      <div className="p-6 flex gap-x-4 gap-y-2 items-center justify-center flex-wrap">
        <ChoosingPeriodButtons />

        <DateRangeSelector isActivityPage />
        <TodayButton />

        <UploadJobsButton />

        <div className="relative">
          <IconButton
            size="none"
            iconSize="md"
            color="basic"
            className="h-[48px] w-[48px]"
            onClick={toggleFilterMenu}
          >
            <Icons.Outlined.Controls.FilterIcon />
          </IconButton>

          {!!appliedFiltersAmount && (
            <Tag
              type="white"
              className="absolute right-[-2px] top-0 !px-1"
            >
              {appliedFiltersAmount}
            </Tag>
          )}
        </div>

        {isFilterMenuOpen && (
          <Filters
            title="Filters"
            isOpen={isFilterMenuOpen}
            closeMenu={setIsFilterMenuOpen}
            type="board"
            className="right-[60px] top-[85px] min-w-[450px] max-w-[450px] z-[9999]"
            onClearAll={hasClearBySearchValues ? handleClearAllFilters : undefined}
            onApplyClick={onApplyClick}
          >
            {renderFilters}
          </Filters>
        )}
      </div>

      <div className="w-screen px-6 mt-3 flex justify-center">
        <div className="flex relative overflow-x-scroll">
          <ActivityGridTable />

          <div
            style={{
              scrollbarWidth: 'none',
              minWidth: `${containerWidth}px`,
              height: `calc(100vh - ${topSpace}px)`,
              paddingBottom: `${DAY_PADDING_BOTTOM}px`,
            }}
            className="absolute top-0 left-0 mt-[74px] overflow-scroll"
          >
            <div className="flex flex-col gap-4">
              {activityId && (
                <ActivityJobProcess
                  activityId={activityId}
                  processIndex={0}
                  setContainerWidth={handleSetContainerWidth}
                  timeZone={timeZone}
                />
              )}

              {!activityId &&
                activitiesByProviderTeams?.map(({ id }, index) => (
                  <ActivityJobProcess
                    key={id}
                    activityId={id}
                    processIndex={index}
                    setContainerWidth={handleSetContainerWidth}
                    timeZone={timeZone}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>

      <AddActivityJobButton
        timeZone={timeZone}
        ownerLocationID={ownerLocationID}
      />
    </div>
  );
};

export { Activities };
