import { FC } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';

import { Inspector } from 'src/shared/types';
import blueMarker from 'src/assets/images/markers/blue-marker.png';

import { PopupBody } from './PopupBody';

type MarketMapProps = {
  inspector: Inspector;
};

const MarkerMap: FC<MarketMapProps> = ({ inspector }) => {
  const markerIcon = new Icon({
    iconUrl: blueMarker,
    iconSize: [25, 41],
  });

  if (!inspector.latitude || !inspector.longitude) {
    return null;
  }

  return (
    <Marker
      position={[inspector.latitude, inspector.longitude]}
      icon={markerIcon}
    >
      <Popup>
        <PopupBody inspector={inspector} />
      </Popup>
    </Marker>
  );
};
export { MarkerMap };
