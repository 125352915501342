import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { EquipmentEntity, PersonEntity, RelationOption, Inspector } from 'src/shared/types';

export const not = (x?: unknown): x is undefined | null => Boolean(x) !== true;

export const camelize = (str: string) =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase(),
    )
    .replace(/\s+/g, '');

export const camelToTitleCase = (camelCaseString: string) => {
  return camelCaseString
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    .replace(/^./, (str) => str.toUpperCase());
};

// | TODO: This should be moved to helpers file.
// | This file was initially created for some reusable generic functions
// | without specific context.
export const sortAssignedPeople = (items: PersonEntity[], assignedItems?: string[]) =>
  [...items].sort((a, b) => {
    if (assignedItems?.includes(a.id)) return 1;
    if (assignedItems?.includes(b.id) && assignedItems?.includes(a.id)) return 0;
    return -1;
  });

export const sortAssignedEquipment = (items: EquipmentEntity[], assignedItems?: string[]) =>
  [...items].sort((a, b) => {
    if (assignedItems?.includes(a.AssetID)) return 1;
    if (assignedItems?.includes(b.AssetID) && assignedItems?.includes(a.AssetID)) return 0;
    return -1;
  });

export const showToastErrorMessage = (message: string, autoCloseMs?: number) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: autoCloseMs,
  });
};

export const getRandomNumber = () => {
  const uuid = uuidv4();

  const extractedPortion = uuid.split('-')[0];
  const decimalNumber = parseInt(extractedPortion, 16);

  return decimalNumber;
};

export const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const truncateText = (text: string | undefined | null, maxLength: number) => {
  if (not(text)) return '';

  if (text.length <= maxLength) return text;

  return `${text.slice(0, maxLength - 3)}...`;
};

export const getAzureUrl = (fileRootPath: string | undefined, path: string | undefined) => {
  if (not(fileRootPath) || not(path)) {
    return '';
  }

  if (path.startsWith('http')) {
    return path;
  }

  return `${fileRootPath}${path}`;
};

export const cleanAndNormalize = (value: string) => {
  return value.trim();
};

export const isStringArray = (value: unknown): value is string[] => {
  return Array.isArray(value) && value.every((item) => typeof item === 'string');
};

export const isMultiselectOption = (option: unknown): option is RelationOption => {
  return typeof option === 'object' && option !== null && 'value' in option && 'label' in option;
};

export const isArrayMultiselectOptions = (value: unknown): value is string[] | RelationOption[] => {
  if (!Array.isArray(value)) {
    return false;
  }

  if (value.every((item) => typeof item === 'string')) {
    return false;
  }

  if (value.every((item) => isMultiselectOption(item))) {
    return true;
  }

  return false;
};

export const formatAddressesIntoInspectors = (inspectors: Inspector[]) => {
  return inspectors.map((inspector) => {
    const { city, state, street, zip, country } = inspector;

    const addressComponents = [street, city, state, zip, country].filter(Boolean);

    const address = addressComponents.join(', ');

    return {
      ...inspector,
      address,
    };
  });
};

export const uncapitalizeString = (str: string) => {
  if (!str) return str;
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export const normalizeUrl = (portalUrl: string | null | undefined) => {
  if (portalUrl && portalUrl.trim()) {
    return portalUrl.startsWith('/') ? portalUrl : `/${portalUrl}`;
  }

  return '/';
};

export const noop = () => {};

export const getFieldValue = <T extends Record<string, unknown>>(state: T, field: keyof T) => {
  const fieldValue = state?.[field];

  if (typeof fieldValue === 'object' && fieldValue !== null && 'value' in fieldValue) {
    return (fieldValue as { value: string }).value;
  }

  if (typeof fieldValue === 'string') {
    return fieldValue;
  }

  return '';
};
