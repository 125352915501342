import { MostUsedJobTitle, PersonEntity, SortOrder } from 'src/shared/types';
import { SidebarFilters, api } from 'src/store/api/api';

const peopleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPeople: builder.query<
      PersonEntity[],
      {
        filters?: SidebarFilters;
        pagination?: {
          page?: string;
          pageSize?: string;
          search?: string;
        };
        sorting?: {
          key?: keyof PersonEntity | null;
          order?: SortOrder;
        };
      }
    >({
      query: ({ filters, pagination, sorting }) => ({
        url: '/people',
        params: {
          filters: {
            providerArea: filters?.providerAreaSidebar,
            providerRegion: filters?.providerRegionSidebar,
            providerBranch: filters?.providerBranchSidebar,
            division: filters?.divisionSidebar,
          },
          pagination,
          sorting,
        },
      }),
      providesTags: () => ['People'],
      transformResponse: (response: { data: PersonEntity[] }) => response.data,
    }),
    getPerson: builder.query<PersonEntity, string>({
      query: (id) => ({
        url: `/people/${id}`,
      }),
      providesTags: () => ['People'],
      transformResponse: (response: { data: PersonEntity }) => response.data,
    }),
    getMostUsedJobTitles: builder.query<MostUsedJobTitle[], ''>({
      query: () => ({
        url: '/people/most-used',
      }),
      providesTags: () => ['People'],
      transformResponse: (response: { data: MostUsedJobTitle[] }) => response.data,
    }),
  }),
});

export const {
  useGetPeopleQuery,
  useLazyGetPeopleQuery,
  useGetPersonQuery,
  useGetMostUsedJobTitlesQuery,
} = peopleApi;
export { peopleApi };
