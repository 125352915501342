import { useParams, useNavigate } from 'react-router-dom';

import { LeafletMap } from 'src/features/admin/LeafletMap';

const INSPECTORS_MODEL_NAME = 'Inspectors';
const AdminMap = () => {
  const navigate = useNavigate();
  const { modelName } = useParams();

  if (!modelName || modelName !== INSPECTORS_MODEL_NAME) {
    navigate('/404', { replace: true });
    return null;
  }
  return <LeafletMap />;
};

export { AdminMap };
