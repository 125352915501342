import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { dayjs as _dayjs } from 'src/shared/utils';

import { COLORS, DATE_FORMAT, DEFAULT_COLOR, LANGUAGE } from '../constants';
import { DatepickerContext } from '../contexts/DatepickerContext';
import { formatDate, nextMonth, previousMonth } from '../helpers';
import { useOnClickOutside } from '../hooks';
import { Period, DatepickerType, ColorKeys } from '../types';
import { getFormattedDate } from '../../details/helpers';

import { Input } from './Input';
import { Calendar } from './Calendar';

const Datepicker: React.FC<DatepickerType> = ({
  timezone = undefined,
  error,
  primaryColor = 'blue',
  value = null,
  onChange,
  configs = undefined,
  asSingle = false,
  asWeek = false,
  as3Days = false,
  placeholder = null,
  separator = '-',
  startFrom = null,
  i18n = LANGUAGE,
  disabled = false,
  inputClassName = null,
  containerClassName = null,
  toggleClassName = null,
  toggleIcon = undefined,
  displayFormat = DATE_FORMAT,
  readOnly = false,
  minDate = null,
  maxDate = null,
  dateLooking = 'forward',
  disabledDates = null,
  inputId,
  inputName,
  startWeekOn = 'sun',
  classNames = undefined,
  popoverDirection = undefined,
  iconPosition = undefined,
  required = false,
  isTimezoneFormatted = false,
  isBorderPrimary = false,
}) => {
  const dayjs = (time?: string | number | Date | _dayjs.Dayjs | null | undefined) => {
    return timezone ? _dayjs(time).tz(timezone) : _dayjs(time);
  };

  // Ref
  const containerRef = useRef<HTMLDivElement | null>(null);
  const calendarContainerRef = useRef<HTMLDivElement | null>(null);

  // State
  const [firstDate, setFirstDate] = useState<_dayjs.Dayjs>(
    startFrom && dayjs(startFrom).isValid() ? dayjs(startFrom) : dayjs(),
  );
  const [secondDate, setSecondDate] = useState<_dayjs.Dayjs>(nextMonth(firstDate));
  const [period, setPeriod] = useState<Period>({
    start: null,
    end: null,
  });
  const [dayHover, setDayHover] = useState<string | null>(null);
  const [inputText, setInputText] = useState<string>('');
  const [inputRef, setInputRef] = useState(React.createRef<HTMLInputElement>());

  // Custom Hooks use
  useOnClickOutside(containerRef, () => {
    const container = containerRef.current;
    if (container) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      hideDatepicker();
    }
  });

  // Functions
  const hideDatepicker = useCallback(() => {
    const div = calendarContainerRef.current;
    if (div && div.classList.contains('block')) {
      div.classList.remove('block');
      div.classList.remove('translate-y-0');
      div.classList.remove('opacity-1');
      div.classList.add('translate-y-4');
      div.classList.add('opacity-0');
      setTimeout(() => {
        div.classList.remove('bottom-full');
        div.classList.add('hidden');
        div.classList.add('mb-2.5');
        div.classList.add('mt-2.5');
      }, 300);
    }
  }, []);

  /* Start First */
  const firstGotoDate = useCallback(
    (date: _dayjs.Dayjs) => {
      const newDate = dayjs(formatDate(date));
      const reformatDate = dayjs(formatDate(secondDate));
      if (newDate.isSame(reformatDate) || newDate.isAfter(reformatDate)) {
        setSecondDate(nextMonth(date));
      }
      setFirstDate(date);
    },
    [secondDate, dayjs],
  );

  const previousMonthFirst = useCallback(() => {
    setFirstDate(previousMonth(firstDate));
  }, [firstDate]);

  const nextMonthFirst = useCallback(() => {
    firstGotoDate(nextMonth(firstDate));
  }, [firstDate, firstGotoDate]);

  const changeFirstMonth = useCallback(
    (month: number) => {
      firstGotoDate(dayjs(`${firstDate.year()}-${month < 10 ? '0' : ''}${month}-01`));
    },
    [firstDate, firstGotoDate, dayjs],
  );

  const changeFirstYear = useCallback(
    (year: number) => {
      firstGotoDate(dayjs(`${year}-${firstDate.month() + 1}-01`));
    },
    [firstDate, firstGotoDate, dayjs],
  );
  /* End First */

  /* Start Second */
  // const secondGotoDate = useCallback(
  //   (date: dayjs.Dayjs) => {
  //     const newDate = dayjs(formatDate(date, displayFormat));
  //     const reformatDate = dayjs(formatDate(firstDate, displayFormat));
  //     if (newDate.isSame(reformatDate) || newDate.isBefore(reformatDate)) {
  //       setFirstDate(previousMonth(date));
  //     }
  //     setSecondDate(date);
  //   },
  //   [firstDate, displayFormat],
  // );

  // const previousMonthSecond = useCallback(() => {
  //   secondGotoDate(previousMonth(secondDate));
  // }, [secondDate, secondGotoDate]);
  //
  // const nextMonthSecond = useCallback(() => {
  //   setSecondDate(nextMonth(secondDate));
  // }, [secondDate]);
  //
  // const changeSecondMonth = useCallback(
  //   (month: number) => {
  //     secondGotoDate(dayjs(`${secondDate.year()}-${month < 10 ? '0' : ''}${month}-01`));
  //   },
  //   [secondDate, secondGotoDate],
  // );

  // const changeSecondYear = useCallback(
  //   (year: number) => {
  //     secondGotoDate(dayjs(`${year}-${secondDate.month() + 1}-01`));
  //   },
  //   [secondDate, secondGotoDate],
  // );
  /* End Second */

  // UseEffects & UseLayoutEffect
  useEffect(() => {
    const container = containerRef.current;
    const calendarContainer = calendarContainerRef.current;

    if (container && calendarContainer) {
      // eslint-disable-next-line etc/no-commented-out-code
      // const detail = container.getBoundingClientRect();
      // const screenCenter = window.innerWidth / 2;
      // const containerCenter = (detail.right - detail.x) / 2 + detail.x;
      // if (containerCenter > screenCenter) {
      //   calendarContainer.classList.add('left-0');
      // }
    }
  }, []);

  useEffect(() => {
    if (value && value.startDate && value.endDate) {
      const startDate = dayjs(value.startDate).year(dayjs().year());
      const endDate = dayjs(value.endDate).year(dayjs().year());
      const validDate = startDate.isValid() && endDate.isValid();
      const condition =
        validDate &&
        (startDate.isSame(endDate) || startDate.isBefore(endDate)) &&
        (formatDate(startDate) !== period.start || formatDate(endDate) !== period.end);
      if (condition) {
        setPeriod({
          start: formatDate(startDate),
          end: formatDate(endDate),
        });

        const textToSet =
          isTimezoneFormatted && timezone
            ? `${getFormattedDate(String(startDate), false, timezone)}${
                asSingle
                  ? ''
                  : ` ${separator} ${getFormattedDate(String(endDate), false, timezone)}`
              }`
            : `${getFormattedDate(String(startDate), false)}${
                asSingle ? '' : ` ${separator} ${getFormattedDate(String(endDate), false)}`
              }`;

        setInputText(textToSet);
      }
    }

    if (
      value &&
      value.startDate === null &&
      value.endDate === null &&
      period.start !== null &&
      period.end !== null
    ) {
      setPeriod({
        start: null,
        end: null,
      });
      setInputText('');
    }
  }, [asSingle, value, displayFormat, separator, isTimezoneFormatted, timezone, dayjs, period]);

  useEffect(() => {
    if (startFrom && dayjs(startFrom).isValid()) {
      if (value?.startDate != null) {
        setFirstDate(dayjs(value.startDate));
        setSecondDate(nextMonth(dayjs(value.startDate)));
      } else {
        setFirstDate(dayjs(startFrom));
        setSecondDate(nextMonth(dayjs(startFrom)));
      }
    }
  }, [startFrom, value, dayjs]);

  // Variables
  const safePrimaryColor = useMemo(() => {
    if (COLORS.includes(primaryColor)) {
      return primaryColor as ColorKeys;
    }
    return DEFAULT_COLOR;
  }, [primaryColor]);
  const contextValues = useMemo(() => {
    return {
      asSingle,
      asWeek,
      as3Days,
      primaryColor: safePrimaryColor,
      configs,
      calendarContainer: calendarContainerRef,
      hideDatepicker,
      period,
      changePeriod: (newPeriod: Period) => setPeriod(newPeriod),
      dayHover,
      changeDayHover: (newDay: string | null) => setDayHover(newDay),
      inputText,
      changeInputText: (newText: string) => setInputText(newText),
      updateFirstDate: (newDate: _dayjs.Dayjs) => firstGotoDate(newDate),
      changeDatepickerValue: onChange,
      placeholder,
      separator,
      i18n,
      value,
      disabled,
      inputClassName,
      containerClassName,
      toggleClassName,
      toggleIcon,
      readOnly,
      displayFormat,
      minDate,
      maxDate,
      dateLooking,
      disabledDates,
      inputId,
      inputName,
      startWeekOn,
      classNames,
      onChange,
      input: inputRef,
      popoverDirection,
    };
  }, [
    asSingle,
    asWeek,
    as3Days,
    safePrimaryColor,
    configs,
    hideDatepicker,
    period,
    dayHover,
    inputText,
    onChange,
    placeholder,
    separator,
    i18n,
    value,
    disabled,
    inputClassName,
    containerClassName,
    toggleClassName,
    toggleIcon,
    readOnly,
    displayFormat,
    minDate,
    maxDate,
    dateLooking,
    disabledDates,
    inputId,
    inputName,
    startWeekOn,
    classNames,
    inputRef,
    popoverDirection,
    firstGotoDate,
  ]);

  const containerClassNameOverload = useMemo(() => {
    const defaultContainerClassName = 'relative w-full text-gray-700';
    // eslint-disable-next-line no-nested-ternary
    return typeof containerClassName === 'function'
      ? containerClassName(defaultContainerClassName)
      : typeof containerClassName === 'string' && containerClassName !== ''
      ? containerClassName
      : defaultContainerClassName;
  }, [containerClassName]);

  return (
    <DatepickerContext.Provider value={contextValues}>
      <div
        className={containerClassNameOverload}
        ref={containerRef}
      >
        <Input
          iconPosition={iconPosition}
          setContextRef={setInputRef}
          error={error}
          required={required}
          isBorderPrimary={isBorderPrimary}
        />

        <div
          className="transition-all ease-out duration-300 absolute !z-[99999] mt-[1px] text-sm lg:text-xs 2xl:text-sm translate-y-4 opacity-0 hidden"
          ref={calendarContainerRef}
        >
          <div className="border border-outlineColor-input-border bg-bgColor-card rounded-lg my-[12px]">
            <div className="flex flex-row">
              <div className="flex items-stretch flex-row space-y-0 space-x-1.5">
                <Calendar
                  date={firstDate}
                  onClickPrevious={previousMonthFirst}
                  onClickNext={nextMonthFirst}
                  changeMonth={changeFirstMonth}
                  changeYear={changeFirstYear}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DatepickerContext.Provider>
  );
};

export { Datepicker };
