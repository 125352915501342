import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SidebarFilters, BoardFilters } from 'src/store/api/api';
import { type RootState } from 'src/store';
import { EquipmentEntity, EquipmentTab, EquipmentTabType } from 'src/shared/types';

export enum FilterMode {
  Default = 'default',
  SurveyAnswer = 'surveyAnswer',
}

export type FilterModeType = FilterMode.Default | FilterMode.SurveyAnswer;

type InitialState = {
  weeks: {
    [key: string]: {
      rows: {
        [key: string]: {
          height: number;
          isOpen: boolean;
        };
      };
    };
  };
  boardFilters: BoardFilters;
  sidebarFilters: SidebarFilters;
  selectedWeek: string;
  listOfLoadingEntityIds: Array<number | string>;
  selectedEquipmentItem: EquipmentEntity | null;
  search: string;
  openEquipmentTab: EquipmentTabType;
  typeBoardFilters: FilterModeType;
};

const initialState: InitialState = {
  weeks: {
    '': {
      rows: {},
    },
  },
  boardFilters: {
    owner: [],
    ownerSite: [],
    jobCategory: [],
    serviceLine: [],
    providerArea: [],
    providerRegion: [],
    providerBranch: [],
    division: [],
    formType: [],
    completedBy: [],
    jobID: [],
    provider: [],
    crew: [],
    health: [],
    status: [],
    delayCategory: [],
  },
  sidebarFilters: {
    providerAreaSidebar: [],
    providerRegionSidebar: [],
    providerBranchSidebar: [],
    divisionSidebar: [],
    equipmentTypeSidebar: [],
  },
  selectedWeek: '',
  listOfLoadingEntityIds: [],
  selectedEquipmentItem: null,
  search: '',
  openEquipmentTab: EquipmentTab.owned,
  typeBoardFilters: FilterMode.Default,
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    changeRowOptions: (
      state,
      action: PayloadAction<{
        selectedWeek: string;
        row: number;
        options: {
          height: number;
          isOpen: boolean;
        };
      }>,
    ) => {
      const { selectedWeek, row, options } = action.payload;

      if (!state.weeks[selectedWeek].rows[row]) {
        state.weeks[selectedWeek].rows[row] = options;

        return;
      }

      if (options.isOpen !== state.weeks[selectedWeek].rows[row].isOpen) {
        state.weeks[selectedWeek].rows[row].isOpen = options.isOpen;
      }

      if (options.height > state.weeks[selectedWeek].rows[row].height) {
        state.weeks[selectedWeek].rows[row].height = options.height;
      }
    },
    changeRowHeight: (
      state,
      action: PayloadAction<{
        selectedWeek: string;
        row: number;
        height: number;
      }>,
    ) => {
      const { selectedWeek, row, height } = action.payload;

      state.weeks[selectedWeek].rows[row].height = height;
    },
    setSelectedWeek: (
      state,
      action: PayloadAction<{
        selectedWeek: string;
      }>,
    ) => {
      const { selectedWeek } = action.payload;

      state.selectedWeek = selectedWeek;

      if (!state.weeks[selectedWeek]) {
        state.weeks[selectedWeek] = {
          rows: {},
        };
      } else {
        state.weeks[selectedWeek].rows = {};
      }
    },
    setSelectedFilters: (
      state,
      action: PayloadAction<{
        type: 'board' | 'sidebar';
        filters: BoardFilters | SidebarFilters;
      }>,
    ) => {
      const { type, filters } = action.payload;

      if (type === 'board') {
        state.boardFilters = filters as BoardFilters;
      } else {
        state.sidebarFilters = filters as SidebarFilters;
      }

      state.weeks[state.selectedWeek].rows = {};
    },
    toggleJobsVisibility: (state) => {
      const selectedWeek = state.weeks[state.selectedWeek];

      const openJobs = Object.values(selectedWeek.rows).reduce((acc, el) => {
        return el.isOpen ? acc + 1 : acc;
      }, 0);

      const newIsOpen = !(openJobs > 0);

      selectedWeek.rows = Object.fromEntries(
        Object.entries(selectedWeek.rows).map(([rowKey, rowData]) => [
          rowKey,
          {
            ...rowData,
            isOpen: newIsOpen,
          },
        ]),
      );
    },
    addLoadingEntityIds: (state, action: PayloadAction<Array<string | number>>) => {
      state.listOfLoadingEntityIds = [...state.listOfLoadingEntityIds, ...action.payload];
    },
    addLoadingEntityId: (state, action: PayloadAction<string | number>) => {
      state.listOfLoadingEntityIds.push(action.payload);
    },
    removeLoadingEntityIds: (state, action: PayloadAction<Array<string | number>>) => {
      state.listOfLoadingEntityIds = state.listOfLoadingEntityIds.filter(
        (id) => !action.payload.includes(id),
      );
    },
    removeLoadingEntityId: (state, action: PayloadAction<string | number>) => {
      state.listOfLoadingEntityIds = state.listOfLoadingEntityIds.filter(
        (id) => id !== action.payload,
      );
    },
    setSelectedEquipmentItem: (state, action: PayloadAction<EquipmentEntity | null>) => {
      state.selectedEquipmentItem = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setOpenEquipmentTab: (state, action: PayloadAction<EquipmentTabType>) => {
      state.openEquipmentTab = action.payload;
    },
    clearRows: (state) => {
      const { selectedWeek } = state;

      state.weeks[selectedWeek].rows = {};
    },
    setTypeBoardFilters: (state, action: PayloadAction<FilterModeType>) => {
      state.typeBoardFilters = action.payload;
    },
  },
});

export const configReducer = configSlice.reducer;
export const configActions = configSlice.actions;
export const selectConfig = (state: RootState) => state.config;
