import { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Inspector } from 'src/shared/types';
import { Button } from 'src/shared/ui/button';
import { Typography } from 'src/shared/ui/typography';
import { useAppDispatch } from 'src/store';
import { adminTableActions, TypeAdminPageModal } from 'src/store/slices';

type PopupBodyProps = {
  inspector: Inspector;
};

const PopupBody: FC<PopupBodyProps> = ({ inspector }) => {
  const {
    id,
    firstName,
    lastName,
    email,
    phone,
    yearsOfExperience,
    inspectorToInspectorCertification,
    inspectorToInspectorCompetency,
  } = inspector;

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const certifications =
    inspectorToInspectorCertification?.map((c) => c.inspectorCertification.name).join(', ') ||
    'N/A';

  const competencies =
    inspectorToInspectorCompetency?.map((c) => c.inspectorCompetency.name).join(', ') || 'N/A';

  const details = [
    {
      label: 'Email',
      value: email,
    },
    {
      label: 'Phone',
      value: phone,
    },
    {
      label: 'Years Of Experience',
      value: yearsOfExperience,
    },
    {
      label: 'Certifications',
      value: certifications,
    },
    {
      label: 'Competencies',
      value: competencies,
    },
  ];

  const handleInspectorDetailsClick = () => {
    navigate('/c2o/Inspectors');
    dispatch(
      adminTableActions.selectRow({
        id,
        typeModal: TypeAdminPageModal.Details,
      }),
    );
    setSearchParams({
      search: id.toString(),
    });
  };
  const handleMakeAssigmentClick = () => {
    navigate('/c2o/Assignments');
    dispatch(
      adminTableActions.selectRow({
        id,
        typeModal: TypeAdminPageModal.Create,
      }),
    );
    dispatch(
      adminTableActions.updateCreateModalValues({
        inspectorId: {
          field: 'inspectorId',
          value: id,
          modelName: 'Assignments',
        },
      }),
    );
  };

  return (
    <div className="flex flex-col gap-2 divide-y">
      <Typography
        className="font-semibold"
        variant="p1"
      >
        {`${firstName} ${lastName}`}
      </Typography>

      <div className="flex flex-col gap-2 py-2">
        {details.map(({ label, value }) => (
          <Typography
            variant="p1"
            key={label}
          >
            <span className="font-semibold">{label}:</span> <span>{value || 'N/A'}</span>
          </Typography>
        ))}
      </div>

      <div className="py-2 flex gap-2 items-center">
        <Button
          onClick={handleInspectorDetailsClick}
          variant="outlined"
        >
          Inspector Details
        </Button>

        <Button
          onClick={handleMakeAssigmentClick}
          variant="outlined"
        >
          Make Assigment
        </Button>
      </div>
    </div>
  );
};

export { PopupBody };
