import { DOMAIN, FALLBACK_TENANT } from 'src/config';

enum Tenant {
  Amerapex = 'amerapex',
  Evergreen = 'evergreen',
  Demo = 'demo',
  Marathon = 'marathon',
  Covestro = 'covestro',
  OmegaTech = 'omega-tech',
  Uses = 'uses',
  Dev = 'dev',
  Test = 'test',
}

type TenantData = {
  [key in Tenant]?: {
    name: Tenant;
  };
};

export const tenantsData: TenantData = {
  [Tenant.Evergreen]: {
    name: Tenant.Evergreen,
  },
};

export const getTenant = () => {
  const isProduction = process.env.NODE_ENV === 'production';

  const envMatcher = /-.*$/;

  const tenant = isProduction
    ? window.location.host.replace(DOMAIN, '').replace(envMatcher, '')
    : FALLBACK_TENANT;

  const env = isProduction ? window.location.host.replace(DOMAIN, '').split('-')[1] || null : null;

  return {
    tenant,
    env,
  };
};
