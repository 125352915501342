import { Model, settings } from 'survey-core';
import { SurveyPDF } from 'survey-pdf';
import dayjs from 'dayjs';
import { SURVEYS_DATA_API_KEY } from 'src/config';

import { DATE_FORMAT } from 'src/shared/utils';

const createSurveyPDFModel = (formJson: string, surveyModel: Model) => {
  const PDFWidth = surveyModel && surveyModel.pdfWidth ? surveyModel.pdfWidth : 210;
  const PDFHeight = surveyModel && surveyModel.pdfHeight ? surveyModel.pdfHeight : 297;
  const options = {
    fontSize: 14,
    margins: {
      left: 10,
      right: 10,
      top: 10,
      bot: 10,
    },
    format: [PDFWidth, PDFHeight],
  };

  const surveyPDF = new SurveyPDF(formJson, options);

  surveyPDF.mode = 'display';

  if (surveyModel) {
    surveyPDF.data = surveyModel.data;
  }

  return surveyPDF;
};

const addCancelButton = ({
  model,
  handleCancel,
}: {
  model: Model;
  handleCancel: (context?: unknown) => void;
}) => {
  model.addNavigationItem({
    id: 'sv-nav-cancel',
    title: 'Cancel',
    action: handleCancel,
    css: 'nav-button',
    innerCss: 'sd-btn nav-input cancel-button',
  });
};

const addPDFButton = ({ model, saveToPdf }: { model: Model; saveToPdf: () => void }) => {
  model.addNavigationItem({
    id: 'survey_save_as_file',
    title: 'Save as PDF',
    action: () => {
      saveToPdf();
    },
  });
};

const navigationCSS = {
  navigation: {
    complete: 'sd-btn nav-input submit-button',
  },
};

const generatePDFFileName = (formName: string) => {
  const formattedText = formName.replace(/\s+/g, '_');
  const dateInfo = dayjs().format(DATE_FORMAT.PDF_FILENAME);

  return `${formattedText}_${dateInfo}`;
};

const initSurveySettings = () => {
  settings.web.onBeforeRequestChoices = (_sender, options) => {
    options.request.setRequestHeader('x-api-key', SURVEYS_DATA_API_KEY);
  };
};

export {
  addCancelButton,
  addPDFButton,
  createSurveyPDFModel,
  generatePDFFileName,
  initSurveySettings,
  navigationCSS,
};
