import clsx from 'clsx';
import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { BoardLayout, TimePeriod } from 'src/shared/types';
import { Button } from 'src/shared/ui/button';
import {
  getLocalMonthDays,
  getRangeOfDatesBasedOnLayout,
  getWeekDaysFromMonday,
} from 'src/shared/utils';
import { RootState, useAppDispatch } from 'src/store';
import { calendarActions } from 'src/store/slices';

const periods = [
  { title: '1 Day', value: TimePeriod.Day },
  { title: '3 Day', value: TimePeriod['3Days'] },
  { title: '7 Day', value: TimePeriod.Week },
  { title: 'Month', value: TimePeriod.Month },
];

const ChoosingPeriodButtons: FC = () => {
  const dispatch = useAppDispatch();
  const { currentLayout: filterPeriod, dates: datesState } = useSelector(
    (state: RootState) => state.calendar,
  );

  const handleChangePeriod = useCallback(
    (period: BoardLayout) => {
      let updatedDates = [];
      switch (period) {
        case TimePeriod.Day:
          updatedDates = getRangeOfDatesBasedOnLayout(datesState[0])(period);
          break;
        case TimePeriod['3Days']:
          updatedDates = getRangeOfDatesBasedOnLayout(datesState[0])(period);
          break;
        case TimePeriod.Week:
          updatedDates = getWeekDaysFromMonday(datesState[0]);
          break;
        case TimePeriod.Month:
          updatedDates = getLocalMonthDays(datesState[0]);
          break;
        default:
          return;
      }

      dispatch(
        calendarActions.changeLayout({
          layout: period,
        }),
      );
      if (updatedDates.length) {
        dispatch(
          calendarActions.changeDates({
            dates: updatedDates,
          }),
        );
        dispatch(
          calendarActions.changeSelectedDate({
            date: updatedDates[0],
          }),
        );
      }
    },
    [datesState, dispatch],
  );

  return (
    <div className="flex gap-3 items-center justify-center">
      {periods.map((period) => {
        const isActiveButton = filterPeriod === period.value;

        return (
          <Button
            key={period.value}
            variant={isActiveButton ? 'filled' : 'outlined'}
            onClick={() => handleChangePeriod(period.value)}
            className={clsx(
              isActiveButton
                ? 'bg-brandingColor-primary-gradient text-white'
                : '!border-brandingColor-primary-gradient !text-brandingColor-primary-gradient',
            )}
          >
            {period.title}
          </Button>
        );
      })}
    </div>
  );
};

export { ChoosingPeriodButtons };
