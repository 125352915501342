import { FC, PropsWithChildren, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { Button } from 'src/shared/ui/button';
import { IconButton } from 'src/shared/ui/iconButton';
import { Avatar } from 'src/shared/ui/avatar';
import { ReactComponent as BurgerIcon } from 'src/assets/icons/outlined/menus/menu.svg';
import { ReactComponent as FilterIcon } from 'src/assets/icons/outlined/controls/filter.svg';
import { ReactComponent as ChevronDownIcon } from 'src/assets/icons/filled/chevrons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'src/assets/icons/filled/chevrons/chevron-up.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/filled/edit/close.svg';
import { ReactComponent as SearchIcon } from 'src/assets/icons/filled/internet&code/search.svg';
import { ReactComponent as CloseCircleIcon } from 'src/assets/icons/outlined/edit/close-circle.svg';
import { RootState, useAppDispatch } from 'src/store';
import {
  getAzureUrl,
  getCurrentDay,
  getFirstDayOfWeek,
  getRangeOfDatesBasedOnLayout,
  normalizeUrl,
} from 'src/shared/utils';
import { calendarActions } from 'src/store/slices/calendar';
import { schedulerBoardSettingsActions } from 'src/store/slices/schedulerBoardSettings';
import { DateRangeSelector } from 'src/shared/ui/dateRangeSelector';
import {
  useGetBoardFiltersQuery,
  useGetPortalProviderSettingsQuery,
  useGetSurveyAnswersFiltersQuery,
} from 'src/store/api';
import {
  configActions,
  modalConfigActions,
  selectConfig,
  selectCurrentUser,
  selectModalConfig,
  FilterMode,
} from 'src/store/slices';
import { useGetProviderQuery } from 'src/store/api/provider';
import { useDebounce } from 'src/shared/hooks/useDebounce';
import { Filters } from 'src/shared/ui/filters';
import { FilterSelect } from 'src/shared/ui/filterselect';
import { Details, ProfileDetails } from 'src/shared/ui/details';
import { ImageViewer } from 'src/shared/ui/imageViewer';
import { TextField } from 'src/shared/ui/textField';
import { SKIP_QUERY, GLOBAL_LAYOUT } from 'src/shared/constants';

import { Tag } from '../tag';

type HeaderProps = {
  className?: string;
  withFilters: boolean;
  withCollapseAll: boolean;
  withWeekNavigation: boolean;
  withToday: boolean;
  withSearch: boolean;
  isNavigationOpen: boolean;
  withUserInfo: boolean;
  withNavigation: boolean;
  toggleNavigation: () => void;
};

const Header: FC<PropsWithChildren<HeaderProps>> = ({
  className,
  withFilters,
  withCollapseAll,
  withWeekNavigation,
  withToday,
  withSearch,
  isNavigationOpen,
  withUserInfo,
  withNavigation,
  toggleNavigation: toggleNavigationMenu,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const selectedRange = useSelector((state: RootState) => state.calendar.currentLayout);
  const config = useSelector(selectConfig);
  const modalConfig = useSelector(selectModalConfig);
  const user = useSelector(selectCurrentUser);

  const { data: configuration } = useGetPortalProviderSettingsQuery({});
  const { data: provider } = useGetProviderQuery('', {
    skip: !user || location.pathname.startsWith(SKIP_QUERY.publicForms),
  });

  const { data: filters = [] } = useGetBoardFiltersQuery(config.boardFilters, {
    skip: !user || location.pathname.startsWith(SKIP_QUERY.publicForms),
  });
  const { data: surveyAnswersFilters = [] } = useGetSurveyAnswersFiltersQuery(
    {},
    {
      skip: !user || location.pathname.startsWith(SKIP_QUERY.publicForms),
    },
  );

  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedQuery = useDebounce(search, 300);

  const currentDay = getCurrentDay();
  const firstDayOfWeek = getFirstDayOfWeek(currentDay);

  const appliedFiltersAmount = Object.values(config.boardFilters).filter(
    (filter) => filter.length > 0,
  ).length;

  const filtersData = (() => {
    switch (config.typeBoardFilters) {
      case FilterMode.Default:
        return filters;
      case FilterMode.SurveyAnswer:
        return surveyAnswersFilters;

      default:
        return filters;
    }
  })();

  const renderFilters = filtersData
    ?.filter((filter) => filter.label !== 'Provider Area' && filter.label !== 'Provider Region')
    .map((filter, index) => (
      <FilterSelect
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        options={filter.options.map((option) => {
          const isObject = typeof option === 'object' && option !== null;

          return {
            value: isObject ? option.value : option,
            label: isObject ? option.label : option,
          };
        })}
        label={filter.label}
        placeholder={`Filter by ${filter.label}`}
        id={filter.label}
        type="board"
      />
    ));

  const handleTodayClick = () => {
    dispatch(calendarActions.changeSelectedDate({ date: firstDayOfWeek }));
    dispatch(
      calendarActions.changeDates({
        dates: getRangeOfDatesBasedOnLayout(firstDayOfWeek)(selectedRange),
      }),
    );
    dispatch(schedulerBoardSettingsActions.changeShouldScrollToToday(true));
    dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(''));
  };

  const toggleJobsVisibility = () => {
    dispatch(configActions.toggleJobsVisibility());
    dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(''));
  };

  const openProfileDetails = () => {
    dispatch(modalConfigActions.setOpenProfileModalPersonEmail(user ? user.email : ''));
  };

  const isAnyRowOpen = Object.values(config.weeks[config.selectedWeek].rows).some(
    (row) => row.isOpen,
  );

  const setIsProfileOpen = (isOpen: boolean) => {
    dispatch(
      modalConfigActions.setOpenProfileModalPersonEmail(
        isOpen ? modalConfig.openProfileModalPersonEmail : '',
      ),
    );
  };

  const handleLogoClickNavigate = () => {
    const portalUrl = normalizeUrl(configuration?.PortalUrl);

    navigate(portalUrl);
  };

  useEffect(() => {
    dispatch(configActions.setSearch(debouncedQuery));
  }, [debouncedQuery, dispatch]);

  return (
    <header
      style={{ minHeight: `${GLOBAL_LAYOUT.header.minHeight}px` }}
      className={clsx(
        `w-full py-[12px] px-[24px] bg-brandingColor-primary-gradient flex flex-row justify-between z-50`,
        className,
      )}
    >
      <div className="flex flex-row justify-center items-center gap-x-6">
        {withNavigation && (
          <IconButton
            color="primary"
            iconSize="lg"
            size="none"
            className="h-[48px] w-[48px]"
            onClick={() => toggleNavigationMenu()}
          >
            {isNavigationOpen ? <CloseIcon /> : <BurgerIcon />}
          </IconButton>
        )}

        <ImageViewer
          src={getAzureUrl(provider?.FileRootPath, configuration?.BannerLogo)}
          className="max-w-[350px] max-h-[48px] w-auto h-auto object-contain cursor-pointer"
          onClick={handleLogoClickNavigate}
        />

        {withToday && (
          <Button
            variant="outlined"
            darkBg
            onClick={handleTodayClick}
          >
            Today
          </Button>
        )}

        {withWeekNavigation && <DateRangeSelector />}
      </div>

      <div className="flex flex-row justify-center items-center gap-x-6 w-fit">
        {withSearch && (
          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search for Jobs"
            className="w-[360px]"
            inputClassName="bg-white"
            startIcon={<SearchIcon className="absolute top-[13px] left-[13px] fill-[#7F8EB4]" />}
            endIcon={
              search ? (
                <IconButton
                  size="md"
                  className="absolute right-[4px] top-[4px]"
                  onClick={() => setSearch('')}
                >
                  <CloseCircleIcon className="fill-[#2E3A59] cursor-pointer" />
                </IconButton>
              ) : undefined
            }
          />
        )}

        {withCollapseAll && (
          <Button
            variant="outlined"
            darkBg
            onClick={toggleJobsVisibility}
            className="w-[132px]"
            endIcon={isAnyRowOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          >
            {`${isAnyRowOpen ? 'Collapse' : 'Expand'} all`}
          </Button>
        )}

        {withFilters && (
          <div className="relative">
            <IconButton
              size="none"
              iconSize="md"
              color="primary"
              className="h-[48px] w-[48px]"
              onClick={() => {
                setIsFilterMenuOpen((prev) => !prev);
                dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(''));
              }}
            >
              <FilterIcon />
            </IconButton>

            {!!appliedFiltersAmount && (
              <Tag
                type="white"
                className="absolute right-[-2px] top-0 !px-1"
              >
                {appliedFiltersAmount}
              </Tag>
            )}
          </div>
        )}

        {isFilterMenuOpen && withFilters && (
          <Filters
            title="Filters"
            isOpen={isFilterMenuOpen}
            closeMenu={setIsFilterMenuOpen}
            type="board"
            className="right-[60px] top-[85px] min-w-[450px] max-w-[600px]"
          >
            {renderFilters}
          </Filters>
        )}

        {withUserInfo && (
          <button
            type="button"
            onClick={openProfileDetails}
          >
            <Avatar className="w-[40px] h-[40px]" />
          </button>
        )}

        {!!modalConfig.openProfileModalPersonEmail && withUserInfo && (
          <Details
            isOpen={!!modalConfig.openProfileModalPersonEmail}
            setIsOpen={setIsProfileOpen}
            title="Profile"
            hasOutsideClick
          >
            <ProfileDetails />
          </Details>
        )}
      </div>
    </header>
  );
};

export { Header };
export type { HeaderProps };
